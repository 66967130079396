import React from 'react';
import { useEffect, useReducer } from 'react'; //useState

export const GameActionReels = (props) => {

    const [state, setState] = useReducer( (state: any, newState: any) => ({...state, ...newState}), { winlines: [], init: true} )

    useEffect(() => {
        if (state.init) {
            let _winlines = props.winlines.map((value: number, index:any)=>{
                return {index: index, number: value, display: 'block'};
            });
            setState({winlines: _winlines, init: false});
        }

    },[state.winlines, props.winlines, state.init]);
  
    function winlineShow(id: number) {
        let _new =  state.winlines.map((element: any, index: number) => {
            return (element.number === id) ? {index: index, number: element.number, display: 'block'} : {index: index, number: element.number, display: 'none'}
        });
        setState({winlines: _new});
    }

    function winlineReset() {
        let _new =  state.winlines.map((element: any, index: number) => {
            return {index: index, number: element.number, display: 'block'};
        });
        setState({winlines: _new});
    }

    return  (
        <div className="cycle-symbols">
             <div className="cycle-symbols-grid--container">
                <div className={'cycle-symbols-grid row_size_' + props.symbols[0].length }>
                        {state.winlines.length > 0 && 
                        <>
                            {state.winlines.map((value: any, index: number)=>{
                                return <div  className="cycle-symbols-winline" key={index.toString()} style={{display: value.display}}>
                                            <img  src={`${process.env.PUBLIC_URL}/img/games/` +  props.gamename + `/winlines/` +  props.gamename + `_winlines_` + value.number + `.png`} alt='' />
                                        </div>
                            })}
                        </>
                        }
                    <div className="cycle-symbols-reels">
                        { props.symbols.map((value: Array<number>, index: number) => {
                            return <div className="cycle-symbols-grid--col" key={index.toString()}>
                                {value.map((value: number, index: number) => {
                                    return <div className='cycle-symbols-grid-row' key={index.toString()}>
                                                <div className='cycle-symbols-grid-image'>
                                                    <img src={`${process.env.PUBLIC_URL}/img/games/` +  props.gamename + `/symbols/` +  props.gamename + `_symbol_id_` + value + `.png`} alt=''/>
                                                </div>
                                        </div>;
                                }).reverse()}
                            </div>;
                        })}
                    </div>
                </div>
            </div>
            <div className="cycle-winlines-details">
                {props.winlines.length > 0 && 
                    <> Winlines numbers:
                        {state.winlines.map((value: any, index: number)=>{
                            return <span onMouseEnter={()=> winlineShow(value.number)}  onMouseOut={()=> winlineReset()} key={index.toString()}> {value.number + 1}</span>
                        })}
                    </>
                }
            </div>
        </div>
    );
};
