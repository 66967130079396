import React from 'react';
import {Container, Row, Col, Spinner} from 'react-bootstrap';
import Currency from 'react-currency-formatter';

import './UserGameHistory.scss';

// modules
import UserDetails from './modules/UserDetails';
import GameDetails from './modules/GameDetails';
import { GameActionReels } from './modules/GameActionReels';

// services
import { LangService } from './services/LangService';

interface IUserGameHistoryProps {
    config: {
      restUrl: string,
      languages: string[]
    };
    urlProps: any;
    package: any;
}
interface IUserGameHistoryState {
    data: any;
    loading: boolean;
    error: any;
    lang: any;
    pack: any;
}

export class UserGameHistory extends React.Component<IUserGameHistoryProps, IUserGameHistoryState> {

   lang = LangService.getInstance();

    constructor(props: IUserGameHistoryProps) {
        super(props);

        this.state = {
            data: undefined,
            error: undefined,
            loading: true,
            lang: undefined,
            pack: undefined
        };

     }

    componentDidMount() {

      this.lang.init();

      // lang set
      this.setState({lang: (this.props.urlProps.lang)? this.props.urlProps.lang : (this.props.config.languages && this.props.config.languages[0])?  this.props.config.languages[0] : 'en'});

      // procede url properties
      if ( this.props.urlProps?.token) { // result && this.props.data.restUrl

        fetch(this.props.config.restUrl + this.props.urlProps.token)
        .then( async (response: any) => {

          const resdata = await response.json();

          resdata.status = 404;
          return resdata;
        })
        .then( (data: any) =>  {

          console.log('data', data)

          if (data.status >= 404 && data.message) {
            this.setState({ error: data, loading: false});
          } else {
            this.setState({ data, loading: false });
          }

        });

      } else {
        this.setState({ error: {succes: false, error: 'Missing token parameter', errorCode:'e0104'}, loading: false });
        // invalid token
      }

    }

    langChange(ev: any) {
      ev.preventDefault();
      const set = ev.target.value;
      this.setState((state) => ({lang: set}));
    }

    render() {

       function getMessageBox(data: any) {

          const errorCode = (data.error.status)? data.error.status: 'undefined';
          const errorMsg = (data.error.message)? data.error.message : 'undefined';
          const errorDetails = (data.error.details && data.error.details[0]) ? data.error.details[0] : 'undefined';

          return <>

                {data.error.details === undefined && data.error.message === undefined ?
                (<>
                    <div className='ugh-message-box'>
                       Missing TOKEN parameter<br/>
                    </div>
                  </>
                ):(
                  <>
                    <div className='ugh-message-box'>
                      Error Message: {errorMsg} <br/>
                      Error Code: {errorCode} <br/>
                      Error Details: {errorDetails}
                    </div>
                  </>
                )}
                </>;
        }

       const maintitle = ( this.lang.GetSingleTranslation('misc','title',this.state.lang) ) ?  this.lang.GetSingleTranslation('misc','title',this.state.lang) : 'main_title';
       const langlabel = ( this.lang.GetSingleTranslation('misc','langlabel',this.state.lang) ) ?  this.lang.GetSingleTranslation('misc','langlabel',this.state.lang) : 'select_language';
       const spinnerlabel = ( this.lang.GetSingleTranslation('misc','spinnerlabel',this.state.lang) ) ?  this.lang.GetSingleTranslation('misc','spinnerlabel',this.state.lang) : 'please_wait_label';

       const actionsLangPack = this.lang.GetTranslations('actions');

       const timeOptions: any = {
          month: 'long',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          timeZone:(this.state.lang === 'pl') ? 'Europe/Warsaw' : 'GMT'
       };

       const timeLang =  (this.state.lang === 'pl') ? 'pl-PL' : 'en-GB';

       //console.log('!!!render ', this.state, this.props.package);

       return (
            <>
            <div className='ugh-header'>
              <Container>
                <Row>
                    <Col>
                      <div className='ugh-header-cred'>
                        <div className='ugh-header-title'>
                            { maintitle }
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className='ugh-header-menu'>
                          {this.props.config.languages &&
                            <div className='ugh-header-langselect'>
                                <label> { langlabel } </label>
                                <select value={this.state.lang} onChange={(evt) => this.langChange(evt)}>
                                    {this.props.config.languages.map((value,index) => {
                                      return  <option key={index.toString()} value={value}>{value}</option>;
                                    })}
                                </select>
                            </div>
                          }
                      </div>
                    </Col>
                  </Row>
              </Container>
            </div>
            <div className='ugh-content'>
              <Container>
                 {this.state.error &&
                  <>
                    {getMessageBox(this.state)}
                  </>
                }

                 {this.state.data ? (
                      <>
                         <div className='output-cycle-details'>
                          <Row>
                            <Col xs='12' lg='4' className='details-section' >
                                <UserDetails
                                    data={this.state.data}
                                    lang={ { pack: this.lang.GetTranslations('request'), set: this.state.lang} }>
                                  </UserDetails>
                            </Col>
                            <Col xs='12' lg='8' className='details-section' >
                              <GameDetails
                                  data={this.state.data}
                                  lang={ { pack: this.lang.GetTranslations('cycle'), set: this.state.lang} }>
                              </GameDetails>
                            </Col>
                          </Row>
                          <Row>
                              <Col xs='12' className='details-section' >
                                <h2>{actionsLangPack.section_header[this.state.lang]}</h2>
                                <div className='output-cycle-actions'>

                                    { this.state.data.gameActions.length === 0 &&
                                        <><span style={{color:'red'}}>EMPTY ACTIONS ARRAY</span></>
                                    }

                                    { this.state.data.gameActions.map((value, index)=> {
                                        return <div key={index.toString()} className='row output-cycle-actions--item'>
                                                    <div className='cycle-action--col cycle-col-index'>
                                                        {index}
                                                    </div>
                                                    <div className='cycle-action--col cycle-col-text'>
                                                        {actionsLangPack.type_name[this.state.lang]}: <strong>{value.type}</strong> <br/>
                                                        {actionsLangPack.bet[this.state.lang]}: <Currency quantity={value.bet} currency={this.state.data.currencyCode}/> <br/>
                                                        {actionsLangPack.win[this.state.lang]}: <Currency quantity={value.win} currency={this.state.data.currencyCode}/> <br/>
                                                        {actionsLangPack.action_time[this.state.lang]}:&nbsp;
                                                          {new Intl.DateTimeFormat(timeLang,timeOptions ).format(new Date(value.actionTime))}  <br/>
                                                        { value.scatterGamesWin !== undefined &&
                                                          <> {actionsLangPack.scatter_game_win[this.state.lang]}: {value.scatterGamesWin}</>
                                                        }
                                                    </div>

                                                    {value.symbols.length > 0 &&
                                                        <div className='cycle-action--col cycle-col-symbols'>
                                                            <GameActionReels
                                                              lang={{ pack: this.lang.GetTranslations('actions'), set: this.state.lang}}
                                                              winlines={value.winLines} symbols={value.symbols}
                                                              gamename={this.state.data.genericName} />
                                                        </div>
                                                    }
                                              </div>;
                                    })}

                                </div>
                              </Col>
                          </Row>
                        </div>
                      </>
                  ) : (
                      <Row className='ugh-section ugh-section--info'>
                        <Col>
                        {this.state.loading &&
                          <>
                            <div className='ugh-spinner'>
                              <Spinner animation='border'/>
                              <div className='ugh-spinner-title'>{spinnerlabel}</div>
                            </div>
                          </>
                        }
                        </Col>
                      </Row>
                  )}
              <Row>
                <Col className='ugh-section-footer'>
                        version: {this.props.package}
                </Col>
              </Row>
              </Container>
            </div>
            </>
          );
    }
}

export default UserGameHistory;
