import React from 'react';
import Currency from 'react-currency-formatter';

interface IGameDetailsProps {
  data: any;
  lang: {
    pack: any,
    set: string
  };
}
interface IGameDetailsState {}

export class GameDetails extends React.Component<IGameDetailsProps, IGameDetailsState> {

    constructor(props: IGameDetailsProps) {
        super(props);
        this.state = {};
     }

    componentDidMount() { }

    render() {

        const langset = this.props.lang.set;
        const langpack = this.props.lang.pack;

        const timeOptions: any = {
            month: 'long',
            day: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZone:(this.props.lang.set === 'pl') ? 'Europe/Warsaw' : 'GMT'
        };

        const timeLang =  (this.props.lang.set === 'pl') ? 'pl-PL' : 'en-GB';

        return (
            <>
                <h2> { langpack?.section_header?.[langset] ? langpack.section_header[langset] : 'Section_header'}</h2>
                <div className='cycle-details--item'>
                    <div className='cycle-details--label'>
                        { langpack?.start_date_time?.[langset] ? langpack.start_date_time[langset] : 'start_date_time'}:
                    </div>
                    <div className='cycle-details--value'>
                        {new Intl.DateTimeFormat(timeLang,timeOptions).format(new Date(this.props.data.start))} / {timeOptions.timeZone}
                    </div>
                </div>

                <div className='cycle-details--item'>
                    <div className='cycle-details--label'>
                        { langpack?.end_date_time?.[langset] ? langpack.end_date_time[langset] : 'end_date_time'}:
                    </div>
                    <div className='cycle-details--value'>
                        {this.props.data.end !== undefined ? (
                            <>
                                {new Intl.DateTimeFormat(timeLang, timeOptions).format(new Date(this.props.data.end))} / {timeOptions.timeZone}
                            </>
                        ):(<> {this.props.data.sessionPhase} </>)
                        }
                    </div>
                </div>
                <div className='cycle-details--item'>
                    <div className='cycle-details--label'>
                        { langpack?.cycle_id?.[langset] ? langpack.cycle_id[langset] : 'cycle_id'}:
                    </div>
                    <div className='cycle-details--value'>{this.props.data.id}</div>
                </div>
                <div className='cycle-details--item'>
                    <div className='cycle-details--label'>
                        { langpack?.session_id?.[langset] ? langpack.session_id[langset] : 'session_id'}:
                    </div>
                    <div className='cycle-details--value'>{this.props.data.sessionId}</div>
                </div>
                <div className='cycle-details--item'>
                    <div className='cycle-details--label'>
                        { langpack?.locale_code?.[langset] ? langpack.locale_code[langset] : 'locale_code'}:
                    </div>
                    <div className='cycle-details--value'>{this.props.data.localeCode}</div>
                </div>
                <div className='cycle-details--item'>
                    <div className='cycle-details--label'>
                        { langpack?.currency_code?.[langset] ? langpack.currency_code[langset] : 'currency_code'}:
                    </div>
                    <div className='cycle-details--value'>{this.props.data.currencyCode}</div>
                </div>
                <div className='cycle-details--item'>
                    <div className='cycle-details--label'>
                        { langpack?.balance_before?.[langset] ? langpack.balance_before[langset] : 'balance_before'}:
                    </div>
                    <div className='cycle-details--value'>
                        {this.props.data.balanceBefore ? (
                            <Currency quantity={this.props.data.balanceBefore} currency={this.props.data.currencyCode}/>
                        ):(<><span style={{color:'red'}}>UNDEFINED</span></>)
                        }
                    </div>
                </div>
                <div className='cycle-details--item'>
                    <div className='cycle-details--label'>
                        { langpack?.balance_after?.[langset] ? langpack.balance_after[langset] : 'balance_after'}:
                    </div>
                    <div className='cycle-details--value'>
                        {this.props.data.balanceAfter ? (
                             <Currency quantity={this.props.data.balanceAfter} currency={this.props.data.currencyCode}/>
                        ):(<><span style={{color:'red'}}>UNDEFINED</span></>)
                        }
                    </div>
                </div>
                { this.props.data.balanceBefore - this.props.data.bet + this.props.data.win !== this.props.data.balanceAfter &&
                    <div className='cycle-details--item cycle-details--item-desc'> 
                        &#9888; <span>{ langpack?.balance_desc?.[langset] ? langpack.balance_desc[langset] : ''}</span>
                    </div>
                }
                <div className='cycle-details--item'>
                    <div className='cycle-details--label'>
                        { langpack?.bet?.[langset] ? langpack.bet[langset] : 'bet'}:
                    </div>
                    <div className='cycle-details--value'>
                        {this.props.data.bet ? (
                             <Currency quantity={this.props.data.bet} currency={this.props.data.currencyCode}/>
                        ):(<><span style={{color:'red'}}>UNDEFINED</span></>)
                        }
                    </div>
                </div>
                <div className='cycle-details--item'>
                    <div className='cycle-details--label'>
                        { langpack?.win?.[langset] ? langpack.win[langset] : 'win'}:
                    </div>
                    <div className='cycle-details--value'>
                        {this.props.data.win || this.props.data.win === 0 ? (
                             <Currency quantity={this.props.data.win} currency={this.props.data.currencyCode}/>
                        ):(<><span style={{color:'red'}}>UNDEFINED</span></>)
                        }
                    </div>
                </div>
            </>
          );
    }
}

export default GameDetails;
