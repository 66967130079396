import React from 'react';

interface IUserDetailsProps {
  data: any;
  lang: {
    pack: any,
    set: string
  };
}
interface IUserDetailsState {}

export class UserDetails extends React.Component<IUserDetailsProps, IUserDetailsState> {

    constructor(props: IUserDetailsProps) {
        super(props);

        this.state = {};
     }

    componentDidMount() {}

    render() {

        const langset = this.props.lang.set;
        const langpack = this.props.lang.pack;

        return (
            <>
             <h2>{ langpack?.section_header?.[langset] ? langpack.section_header[langset] : 'Section_header'}</h2>
                <div className='cycle-details--item'>
                    <div className='cycle-details--label'> { langpack?.game_name?.[langset] ? langpack.game_name[langset] : 'game_name'}: </div>
                    <div className='cycle-details--value'>{this.props.data.gameTitle}</div>
                </div>
                <div className='cycle-details--item'>
                    <div className='cycle-details--label'> { langpack?.game_id?.[langset] ? langpack.game_id[langset] : 'game_id'}: </div>
                    <div className='cycle-details--value'>{this.props.data.gameId}</div>
                </div>
                <div className='cycle-details--item'>
                    <div className='cycle-details--label'>{ langpack?.player_id?.[langset] ? langpack.player_id[langset] : 'player_id'}:</div>
                    <div className='cycle-details--value'>{this.props.data.playerId}</div>
                </div>
                <div className='cycle-details--item'>
                    <div className='cycle-details--label'>{ langpack?.skin_id?.[langset] ? langpack?.skin_id[langset] : 'skin_id'}:</div>
                    <div className='cycle-details--value'>{this.props.data.skinId}</div>
                </div>
            </>
          );
    }
}

export default UserDetails;
